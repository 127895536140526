import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DefaultPicture from '../../assets/conception-illustration.png'
import styled from 'styled-components'

const OurPresentationWrapper = styled.div`
    background: transparent;
    color: #000;
`
const OurPresentationImg = styled.img`
    border-radius: 10px;
    width: 95%;
    height: 85%;
`
const OurPresentationHeading = styled.h1`
    margin-bottom: 10px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    font-size: 60px;
    text-align: center;
    @media (max-width: 463px) {
        font-size: 50px;
        font-weight: 500;
    }
`
const OurPresentationText = styled.p`
    color: rgba(198, 201, 216, 0.75);
    font-size: 18px;
    line-height: 30px;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
    text-align: justify;
    @media (max-width: 1024px) {
        font-size: 15px !important;
    }
`
const OurPresentationJob = styled.h2`
    margin-bottom: 10px;
    font-weight: 400;
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    text-align: justify;
`
const OurPresentationAuthor = styled.p`
    color: rgba(198, 201, 216, 0.75);
    font-size: 18px;
    line-height: 30px;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
    text-align: justify;
`

function OurPresentation({
    title,
    picture,
    job,
    description,
    citation,
    auteur,
}) {
    return (
        <OurPresentationWrapper>
            <Container>
                <Row>
                    <Col lg={6}>
                        <OurPresentationImg src={picture} />
                    </Col>
                    <Col lg={6}>
                        <OurPresentationHeading className="pt-1">
                            {title}
                        </OurPresentationHeading>
                        <Container>
                            <OurPresentationJob>{job}</OurPresentationJob>
                            <OurPresentationText className="py-2">
                                {description}
                            </OurPresentationText>
                            <OurPresentationText>
                                {citation}
                            </OurPresentationText>
                            <OurPresentationAuthor>
                                {auteur}
                            </OurPresentationAuthor>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </OurPresentationWrapper>
    )
}

OurPresentation.propTypes = {
    title: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    citation: PropTypes.string.isRequired,
    auteur: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
}

OurPresentation.defaultProps = {
    title: '',
    picture: DefaultPicture,
    description: '',
    citation: '',
    auteur: '',
    job: '',
}

export default OurPresentation
