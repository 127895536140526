import ProjetPresentation from '../../components/ProjetPresentation'
import projects from '../../assets/projects.jpg'
import styled from 'styled-components'
import { projectsPresentation } from '../../datas'
import { Container, Row, Col } from 'react-bootstrap'
import Typewriter from 'typewriter-effect'

const ProjectsWrapper = styled(Container)`
    background: linear-gradient(70deg, #101141, #7370b5);
`
const FirstCol = styled(Row)`
    background-image: url(${(props) => props.img});
    background-color: #010101;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-bottom: 30px;
`
const ProjectsContent = styled.div`
    padding: 125px 0px;
`
const ProjectsTitle = styled.div``

const PresenstationsText = styled.h1`
    font-size: 18px;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 3px;
    color: #fff !important;
`
const ProjectsText1 = styled.h1`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: #fff;
    @media (max-width: 465px) {
        font-size: 50px;
        font-weight: 500;
    }
`

const StyledSpan = styled.span`
    color: #584995;
`
const StyledSpan2 = styled.span`
    color: #c33c8e;
`

function Projects() {
    return (
        <ProjectsWrapper fluid>
            <FirstCol img={projects}>
                <Container>
                    <ProjectsContent>
                        <ProjectsTitle>
                            <PresenstationsText>
                                <Typewriter
                                    options = {{delay:10}}
                                    onInit={(typewriter) => {
                                        
                                        typewriter
                                            .typeString(
                                                'Chez nous, vous verrez en plus, certains des projets les plus innovants.'
                                            )
                                            .start()
                                    }}
                                />
                            </PresenstationsText>
                            <ProjectsText1>
                                <StyledSpan>NOS</StyledSpan> <br />
                                <StyledSpan2>PROJETS !</StyledSpan2>
                                <br />
                            </ProjectsText1>
                        </ProjectsTitle>
                    </ProjectsContent>
                </Container>
            </FirstCol>
            <Row>
                {projectsPresentation.map((project, index) => (
                    <Col lg="12" md="12">
                        <ProjetPresentation
                            key={`${project.title}-${index}`}
                            title={project.title}
                            picture={project.picture}
                            description={project.text}
                        />
                    </Col>
                ))}
            </Row>
        </ProjectsWrapper>
    )
}

export default Projects
