import React from 'react'
import ReactDOM from 'react-dom'
import Header from './components/Header'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import GlobalStyle from './utils/style/GlobalStyle'
import Home from './pages/Home'
import Freelances from './pages/Freelances'
import Error from './components/Error'
import Presentations from './pages/Presentations'
import Footer from './components/Footer'
import Services from './pages/Services'
import Projects from './pages/Projects'
import QuiSommesNous from './pages/QuiSommesNous'

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <GlobalStyle />
            <Header />
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/freelances">
                    <Freelances />
                </Route>
                <Route path="/projects">
                    <Projects />
                </Route>
                <Route path="/about">
                    <QuiSommesNous />
                </Route>
                <Route path="/services">
                    <Presentations />
                </Route>
                <Route path="/service/:serviceName">
                    <Services />
                </Route>
                <Route path="*">
                    <Error />
                </Route>
            </Switch>
        </Router>
        <Footer />
    </React.StrictMode>,
    document.getElementById('root')
)
