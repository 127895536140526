import styled from 'styled-components'
import {
    BsWhatsapp,
    BsArrowUpCircleFill,
    BsPhoneVibrate,
    BsFacebook,
} from 'react-icons/bs'
import { Nav, Container, Col, Row } from 'react-bootstrap'

const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-top: 2px solid black;
    background-color: #25304d;
    color: white;
    @media (max-width: 1105px) {
        max-height: 550px;
    }
    @media (max-width: 998px) {
        max-height: 750px;
    }
    @media (max-width: 988px) {
        max-height: 1300px;
    }
    @media (max-width: 575px) {
        max-height: 1600px;
    }
`

const MyNavLink = styled(Nav.Link)`
    position: fixed;
    bottom: 50px;
    right: 25px;
    height: 40px;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
`
const StyledBsWhatsapp = styled(BsWhatsapp)`
    width: 40px;
    height: 40px;
    color: #fff;
    &:hover {
        color: green;
    }
    @media (max-width: 860px) {
        color: green;
    }
`
const StyledBsArrowUpCircleFill = styled(BsArrowUpCircleFill)`
    position: fixed;
    bottom: 50px;
    left: 20px;
    opacity: 0.5;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: #fff;
    &:hover {
        color: blue;
    }
    @media (max-width: 860px) {
        color: blue;
    }
`
const MyLink = styled(Nav.Link)`
    padding: 5px 0;
    margin: 0;
    border: 0;
    color: #e7e7e7;
`
const Presentation = styled(Col)``
const Social = styled(Col)``
const Copyright = styled(Col)``

const contact = '2250778475178'

const sections = [
    {
        titre: "DEVELOPPEMENT WEB ET MOBILE",
        liens: [
            {
                titre: "Site Vitrine",
                lien: "/service/websites/#vitrine"
            },
            {
                titre: "E-Commerce",
                lien: "/service/websites/#ecommerce"
            },
            {
                titre: "Blog",
                lien: "/service/websites/#blog"
            },
            {
                titre: "Applications Mobiles",
                lien: "/service/mobileapps"
            }
        ]
    },
    {
        titre: "CONCEPTIONS GRAPHIQUES",
        liens: [
            {
                titre: "Affiches Publicitaires",
                lien: "/service/posters/#poster"
            },
            {
                titre: "Flyer",
                lien: "/service/posters/#flyer"
            },
            {
                titre: "Kakémono",
                lien: "/service/posters/#kakemono"
            },
            {
                titre: "Montage Vidéo",
                lien: "/service/posters/#spot"
            }
        ]
    },
    {
        titre: "SEO ET MARKETING DIGITAL",
        liens: [
            {
                titre: "Nom de Domaine et Mails Pro",
                lien: "/service/seo/#domaine"
            },
            {
                titre: "Référencement SEO",
                lien: "/service/seo/#referencement"
            },
            {
                titre: "Community Management",
                lien: "/service/seo/#com"
            }
        ]
    }
]

function Footer() {
    const today = new Date().getFullYear()
    const todayDate = today.toLocaleString()
    return (
        <FooterContainer className="content-center">
            <Container className="g-5">
                <MyNavLink key={"contact"}
                    href={`https://api.whatsapp.com/send?phone=` + contact}
                >
                    <StyledBsWhatsapp />
                </MyNavLink>
                <Row className="mt-3" key={"section"}>
                    <Presentation
                        lg={4}
                        md={6}
                        sm={12}
                        className="pt-2 box-content"
                        key={"pres"}
                    >
                        <strong>LOCALISATION</strong>
                        <Container className="pt-3 ml-0 border-l-0 p-0">
                            Startup de Création de contenus digitaux en Côte
                            d'Ivoire
                            <br />
                            <address>INP-HB - Yamoussoukro, BP 1093 
                                <MyLink href="tel:+2250173154967">
                                    <BsPhoneVibrate /> +225 01 73 154 967
                                </MyLink>
                                <MyLink href={`mailto:service.client@aktaionartandtechs.com`}>
                                    service.client@aktaionartandtechs.com
                                </MyLink>
                            </address>
                        </Container>
                    </Presentation>
                    {sections.map((section) => (
                        <Col lg={4} md={6} sm={12} className="pt-2 box-content">
                            <strong>{section.titre}</strong>
                            <Container key={section.titre}>
                                {section.liens.map((lien) => (
                                    <MyLink href={lien.lien} key={lien.titre}>{lien.titre}</MyLink>
                                ))}
                            </Container>
                        </Col>
                    ))}
                    <Social lg={4} md={6} sm={12} className="pt-2" key={"social"}>
                        <strong>RESEAUX SOCIAUX</strong>
                        <Container>
                            <MyLink href={`https://api.whatsapp.com/send?phone=` + contact}>
                                <pre><BsWhatsapp className="m-0"/>    WHATSAPP</pre></MyLink>
                            <MyLink href="https://www.facebook.com/profile.php?id=100072997055075">
                                <pre><BsFacebook />    FACEBOOK</pre></MyLink>
                        </Container>
                    </Social>
                </Row>
                <Row className="mt-3 text-center" key={"copyright"}>
                    <Copyright className="text-black self-center" lg={12} key={"copy"}>
                        Copyright &copy; {todayDate}
                    </Copyright>
                </Row>
                <StyledBsArrowUpCircleFill
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }}
                />
            </Container>
        </FooterContainer>
    )
}

export default Footer
