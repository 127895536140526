import logo from '../assets/logo.jpg'
import carte from '../assets/carte.jpg'
import flyer from '../assets/flyer.jpg'
import brochure from '../assets/brochure.jpg'
import brand from '../assets/brand.jpg'
import kakemono from '../assets/kakemono.jpg'
import spot from '../assets/spot.jpg'
import poster from '../assets/poster.jpg'
import PosterPicture from '../assets/poster-illustration.svg'
import LogoPicture from '../assets/logo-illustration.svg'
import SeoPicture from '../assets/seo-illustration.jpg'
import MobilePicture from '../assets/web-illustration.png'
import WebPicture from '../assets/mobile-illustration.png'
import siteVitrine from '../assets/site-vitrine.png'
import siteECommerce from '../assets/site-e-commerce.png'
import siteFonctionnel from '../assets/site-fonctionnel.png'
import refPicture from '../assets/ref.jpg'
import domaine from '../assets/domains.jpg'
import comMan from '../assets/com-manager.jpg'
import siteIntranet from '../assets/site-intranet.png'
import ecomProj from '../assets/ecommerce-project.jpg'
import cartoon from '../assets/cartoon-project.jpg'
import library from '../assets/books-online.jpg'
import siteBlog from '../assets/site-blogs.png'
import meisse from '../assets/Meisse.jpg'
import sinaly from '../assets/Sinaly.jpg'
import ogoumond from '../assets/Ogoumond.jpg'
import lasme from '../assets/Lasme.jpg'
import ofak from '../assets/Ofak.JPG'
import presentation from '../assets/presentation.jpg'
import vision from '../assets/vision.jpg'
import decouvrez from '../assets/decouvrez.jpg'

export const WeAreProfiles = [
    {
        title: 'NOTRE DIRECTION',
        illustration: presentation,
        details:
            "AKTAION SARL est dirigée par un Conseil d'Administration constitué de ses membres fondateurs qui sont des jeunes ivoiriens venus de divers horizons du monde des TICs avec un Directeur Général à sa tête. Les dirigeants de AKTAION ont opté pour une SARL comme statut juridique.",
    },
    {
        title: 'NOTRE EQUIPE TECHNIQUE',
        illustration: decouvrez,
        details:
            "Elle est constituée de Développeurs, Designers, Vidéographes, Rédacteurs Web, Référenceurs SEO, Community Managers et bien d'autres pour assurer la réussite de vos projets de marketing digital et services sur tous les volets possibles. Toutes les tâches sont réalisées par cette équipe sans aucune sous-traitance.",
    },
    {
        title: 'NOTRE VISION',
        illustration: vision,
        details:
            "Persuadés du fait que la Côte d'Ivoire peut devenir un Creuset Technologique dans la sous-région Ouest-Africaine et même en Afrique, Nous sommes déterminés à combiner, à travers AKTAION SARL, notre savoir-faire et notre passion pour aider toutes PME Ivoiriennes à faire leur Digitalisation.",
    },
]

export const freelanceProfiles = [
    {
        name: 'Méissé FOFANA',
        picture: meisse,
        jobTitle: 'Developpeur Web - Fullstack',
        description:
            'Le développement web est ma passion ; je le partage avec vous, en vous montrant vos activités au monde entier grâce à internet.',
        citation:
            "N’importe quel idiot peut écrire du code qu'un ordinateur peut comprendre. Les bons programmeurs écrivent du code que les humains peuvent comprendre.",
        auteur: 'Martin Fowler',
    },
    {
        name: 'Mel Patrick LASME',
        picture: lasme,
        jobTitle: 'Infographiste',
        description:
            'La conception graphique est ma vocation. Mes services vous feront voyager dans le monde fabuleux du design !',
        citation:
            'Il y a trois réponses possibles à une pièce design - oui, non et wow ! Wow est la réaction que vous devez rechercher.',
        auteur: 'Milton GLASER',
    },
    {
        name: 'Abdoul-Kader OUATTARA',
        picture: ofak,
        jobTitle: 'Infographiste',
        description:
            'Je mets ma simplicité créative à votre service dans le but de vous donner de la valeur auprès de votre audience.',
        citation:
            'La simplicité se démarque alors que la complexité va se perdre inexorablement dans la foule.',
        auteur: 'Kevin Barnett',
    },
    {
        name: 'Nignabetien Sinaly OUATTARA',
        jobTitle: 'Developpeur Web - Frontend',
        picture: sinaly,
        description:
            "Passionné de programmation et de graphisme, c'est avec amour et passion que je mettrai sur pied vos différentes idées.",
        citation:
            'Il semble que la perfection soit atteinte non quand il n’y a plus rien à ajouter, mais quand il n’y a plus rien à retrancher.',
        auteur: 'Antoine de Saint-Exupéry, “Terre des hommes”',
    },
    {
        name: 'Don Ced OGOUMOND',
        picture: ogoumond,
        jobTitle: 'Digital Marketer / Infographiste',
        description:
            "La qualité des services d'une entreprise dépend de son système de management. C'est pourquoi à AKTAION, nous travaillons d'arrache-pied pour vous offrir les meilleurs services possibles !",
        citation:
            "Lorsqu'une chose évolue, tout ce qui est autour évolue de même",
        auteur: 'Paulo Coelho',
    },
]

export const projectsPresentation = [
    {
        title: 'ATHENA CENTER',
        text: "E-Commerce, ça vous dit ? Bah oui, certainement ! Mais, E-Commerce à l'Africaine, ça vous parle ? Certainement pas ! ATHENA CENTER est un projet de AKTAION ART AND TECHS qui vise à révolutionner le Commerce informel Ivoirien en la Digitalisant. A travers ce projet, nous envisageons créer des Centres Commerciaux Numériques par ville pour faciliter les échanges et la livraison de colis.",
        picture: ecomProj,
    },
    {
        title: 'LA BIBLIO',
        text: "Comme son nom l'indique, c'est une Bibliothèque Numérique disponile pour tous les amoureux et amateurs de lecture après qu'ils se soient inscrits. Avec des Ouvrages Numériques de tout genre, Archives de nos villes et pays, Webtoon et Mangas y compris, Nous ambitionnons faire de LA BIBLIO un Giganteste Domaine accessile juste avec un Smartphone ou un Ordinateur Portable. ",
        picture: library,
    },
    {
        title: "L'ENFO",
        text: "Soucieux de l'avenir de la prochaine génération Ivoirienne dans le monde du 3.0, l'ENFO est un projet de Cartoon pour apprendre aux plus petits les bases de l'Informatique. D'où son l'ENFO pour \"ENFANT+INFORMATIQUE\". C'est ainsi qu'une plateforme sera mise prochainement en ligne dans ce sens pour permettre aux parents d'inscrire leurs enfants afin que ces derniers puissent apprendre tout en s'amusant.",
        picture: cartoon,
    },
]

export const ourPresentations = [
    {
        title: 'Affiches Publicitaires et Vidéos',
        text: "Vous voulez faire la publicité d'un évènement, d'un produit ou d'une entreprise, alors vous avez sûrement besoin d'une affiche ou d'une vidéo.",
        service: 'posters',
        picture: PosterPicture,
    },
    {
        title: 'Les Sites Web ',
        text: 'Plus de cinq (5) milliards de personnes utilisent internet partout dans le monde entier avec plus de deux (2) milliards de site web déjà disponible sur la toile.',
        service: 'websites',
        picture: WebPicture,
    },
    {
        title: 'Les Applications Mobiles',
        text: "Quoi de mieux qu'avoir une application en son nom ? Que ce soit une bibliothèque en ligne, un gestionnaire de votre entreprise ou bien d'autres, ça vous facilite la tâche.",
        service: 'mobileapps',
        picture: MobilePicture,
    },
    {
        title: 'Logos et Cartes',
        text: 'Donner une identité à votre activité avec un logo qui dit tout sur votre vision et vos objectifs tout en étant simple. Avec les cartes, soyez plus professionels !',
        service: 'logos',
        picture: LogoPicture,
    },
    {
        title: "SEO et Marketing Digital",
        text: "Pourquoi les entreprises utilisent-elles le marketing digital ou font appel à une agence de marketing digital ? Tout simplement parce qu’une stratégie digitale offre de multiples avantages, applicables à tous types d’entreprises, de taille et de moyens différents.",
        service: 'seo',
        picture: SeoPicture
    }
]

export const affiches = [
    {
        id:"poster",
        name: 'Affiche Publicitaire',
        picture: flyer,
        description:
            "L'affiche publicitaire est un élément important de votre communication visuelle qui doit s'intégrer aux paysages urbains et attirer l'attention sur le message que vous souhaitez transmettre ; que ce soint des messages verbaux (texte, slogan) ou des messages visuels (représentation d'une marque, d'un produit, etc).",
    },

    {
        id: "kakemono",
        name: 'Kakémono',
        picture: kakemono,
        description:
            "Il sert à mettre en avant une marque, un produit ou un service. Il peut se décliner via un système d'accroches murales, un système auto-porteur en aluminium ou bien un enrouleur. Il s'agit d'un support publicitaire particulièrement avantageux puisqu'il est nomade et facile à transporter. Il servivra à donner plus d'impact à vos stands.",
    },
    {
        id:"flyer",
        name: 'Flyer',
        picture: poster,
        description:
            "Outil de communication apprécié de tous, le flyer est un produit indémodable. Utilisé pour promouvoir des soirées ou des évènements, pour inviter à des réceptions ou à un vernissage, il est utilisé dans de très nombreux cas. C'est un outil moderne qui peut véhiculer rapidement un message à un grand nombre de personnes. Son partage est assez facile et rapide car il est la plus plupart du temps numérique. Vous aurez les flyers les plus attractifs chez nous.",
    },
    {
        id: "brochure",
        name: 'Brochure',
        picture: brochure,
        description:
            'L’objectif d’une brochure publicitaire est de donner au client ou au consommateur plus de détails sur votre entreprise. En fait, elle présente le commerce de manière globale (date de création, valeurs, chiffres-clés…) ou bien parle en détail d’une offre commerciale (vente, voyage touristique…).',
    },

    {
        id:"spot",
        name: 'Spot Vidéo',
        picture: spot,
        description:
            "Pour vous, un spot publicitaire vidéo demeure un formidable outil pour véhiculer vos idées, et convaincre celles et ceux qui le regardent de passer à l'action. La vidéo est un format persuasif et engageant. Par rapport au texte, elle permet de faire passer un message de façon plus directe sur une période condensée.",
    },
]

export const logos = [
    {
        id:'logo',
        name: 'Logos',
        picture: logo,
        description:
            'Emblème d’une entreprise, le logo est le pilier porteur de l’image d’une entreprise. Un logo professionnel permet à une entreprise de se démarquer de ses concurrents, de véhiculer de bonnes valeurs et d’anticiper les tendances à venir.',
    },
    {
        id:"carte",
        name: 'Carte De Visite',
        picture: carte,
        description:
            "Le carte de visite permet au professionnel de transmettre des informations essentielles à propos de son entreprise durant une prise de contact, il est donc primordial de la réaliser correctement et d'y faire figurer les informations essentielles.",
    },

    {
        id:'brand',
        name: 'Branding Animation De Logo',
        picture: brand,
        description:
            "Faites bonne impression avec l'animation de votre logo. Apportez une touche d'originalité à votre identité visuelle en animant votre logo. Une première impression positive vous aidera à attirer des clients potentiels vers vous!",
    },
]

export const sites = [
    {
        id:'ecommerce',
        name: 'Site E-Commerce',
        picture: siteECommerce,
        description:
            "Un site e-commerce vous permet mieux cibler les clients, en leur proposant des contenus adaptés, de les fidéliser, de les informer grâce à la newsletter, et donc de mieux les connaître. De plus, un site e-commerce permet de toucher une nouvelle clientèle et de vendre partout en Côte d'Ivoire, ou à l'international, et de gagner en notoriété.",
    },
    {
        id:'vitrine',
        name: 'Site Vitrine',
        picture: siteVitrine,
        description:
            "Pour une entreprise, les principaux avantages de la mise en place d'un site vitrine sont : Augmenter sa visibilité sur le web et profiter du trafic internet pour promouvoir sa marque. Élargir sa base clientèle et atteindre de nouveaux prospects, sans être géographiquement limité par sa zone de chalandise.",
    },
    {
        id:'blog',
        name: 'Blog',
        picture: siteBlog,
        description:
            "Le blog personnel est un média social de publication qui permet de capter rapidement une cible large de lecteurs. C'est un outil pour partager du contenu de qualité en mettant en ligne des informations sous forme de journal et développer ainsi sa notoriété personnelle.",
    },
]

export const mobileapps = [
    {
        name: 'App Fonctionnelle',
        picture: siteFonctionnel,
        description:
            "Les applications fonctionnelless sont celles qui permettent le plus grand niveau d’interactivité avec l’utilisateur. Ici, l'application va remplir une fonction bien précise. Comme par exemple Facebook qui vous met en relation avec un réseau d’amis et vous permet de partager du contenu. Ou encore Google Maps qui vous permet d’établir un itinéraire, Airbnb qui vous permet de réserver un logement, Kayak qui vous permet de comparer des vols…",
    },
    {
        name: 'Intranet',
        picture: siteIntranet,
        description:
            "Un intranet est un réseau privé, dédié à l’entreprise et plus sûr qu’internet. Les employés ne peuvent accéder qu’à certaines parties, en fonction de leur rôle. Un intranet vous facilite le partage et l’accès aux informations au sein de votre structure, améliore votre communication interne, augmente votre productivité et vous donne accès aux outils internes à l’entreprise et vous permet même d'avoir un réseau social privé.",
    },
]

export const seo = [
    {
        id: 'domaine',
        name : 'Noms de domaine et Mails Pro',
        picture: domaine,
        description:
            "L'email est depuis des années le moyen de communication de référence au sein des entreprises. Que ce soit pour de la communication interne ou externe, l'email est le moyen le plus efficace pour être sûr que notre interlocuteur reçoive bien notre message. Il existe, bien entendu, des messageries gratuites et accessibles à tous, mais elles sont plutôt recommandées pour les particuliers. Pour les entreprises, il est plus judicieux d'avoir des mails professionnels avec leurs propres noms de domaine ; ceci augmente leur crédibilité. Avouez qu'un devis provenant d'un \"xx@gmail.com\" est toujours moins crédible qu'un devis envoyé de \"xx@nom-de-la-marque.com\"."
    },
    {
        id: 'com',
        name : 'Community Management',
        picture: comMan,
        description:
            "Il y a 10 ans, certains se demandaient si les entreprises avaient vraiment besoin de quelqu’un pour “s’occuper” des Réseaux Sociaux. Aujourd’hui, force est de constater que la question ne se pose plus. Vision stratégique, capacité d’analyse, gestion de campagnes publicitaires, conception avec des influenceurs, le Community Management s’est profondément transformé en quelques années. Les compétences requises pour exercer ce métier sont bien plus vastes qu’auparavant. Vous souhaitez développer la e-notoriété de votre marque, améliorer la visibilité de vos produits et services, créer du lien avec vos clients et prospects ? Vous avez besoin des services d’un Community Manager !"
    },
    {
        id: 'referencement',
        name: 'Référencement SEO',
        picture: refPicture,
        description:
            "Lorsque vous créez votre page web, vous souhaitez que les autres la visitent, mais si votre site n’est pas optimisé pour les moteurs de recherche, vous aurez très peu de trafic. Si vous tenez un site e-commerce, par exemple, votre activité perdra directement en rentabilité. Le référencement naturel, encore applé SEO, est un réel avantage de communication car, s’il est bien réalisé, peut générer un taux de trafic important. Ces personnes qui visitent votre site sont des clients potentiels. Un bon référencement est donc la base solide d’une bonne campagne de communication. Il vaut mieux donc confier un travail d'une telle envergure pour votre service à des professionnels."
    }
]