import { Carousel } from 'react-bootstrap'
import styled from 'styled-components'
import Image from '../../assets/infographie.png'
import Image2 from '../../assets/web.png'
import Image3 from '../../assets/cm.png'

const MyCarousel = styled(Carousel)`
    width: 1100px;
    height: 500px;
    margin: auto;
    color: #fff;
    @media (max-width: 450px) {
        height: 250px;
        width: auto;
    }
`

const StyledImage = styled.img`
    height: 400px;
    @media (max-width: 450px) {
        height: 200px;
    }
`

const carous =[
    {
        id: "Les différents types de site web",
        image: Image,
    },
    {
        id: "Les conceptions graphiques",
        image: Image2,
    },
    {
        id: "Les services d'Aktaion Art and Techs",
        image: Image3,
    }
]

function Service() {
    return (
        <MyCarousel fade variant="dark">
            {carous.map((item)=>(
                <Carousel.Item key={`image-${item.id}`}>
                    <StyledImage
                        className="d-block w-100"
                        src={item.image}
                        alt={item.id}
                    />
                </Carousel.Item>
            ))}
            
        </MyCarousel>
    )
}

export default Service
