import styled from 'styled-components'
import HomeIllustration from '../../assets/home-illustration.svg'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import background from '../../assets/background.jpg'
import {
    BsFillAwardFill,
    BsFillBrushFill,
    BsFillCameraVideoFill,
} from 'react-icons/bs'
import Typewriter from 'typewriter-effect'

const MyContainer = styled(Container)`
    background: linear-gradient(70deg, #101141, #7370b5) !important;
`
const HomeContainer = styled.div`
    margin: 35px;
    margin-bottom: 0px;
    @media (max-width: 994px) {
        padding-top: 70px;
    }
`
const FirstCol = styled(Row)`
    background-image: url(${(props) => props.img});
    background-color: #010101;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
`

const RightCol = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding: 30px;
`

const StyledTitle = styled.h2`
    padding-bottom: 30px;
    line-height: 40px;
    color: #fff;
    font-weight: 400px;
`
const Illustration = styled.img`
    flex: 1;
    margin-top: 30px;
    padding-bottom: 30px;
    max-width: 700px;
    @media (max-width: 1320px) {
        max-width: 560px;
    }
    @media (max-width: 1201px) {
        max-width: 500px;
    }
    @media (max-width: 1099px) {
        max-width: 450px;
    }
    @media (max-width: 670px) {
        max-width: 395px;
    }
    @media (max-width: 539px) {
        max-width: 390px;
    }
    @media (max-width: 447px) {
        max-width: 300px;
    }
    @media (max-width: 360px) {
        max-width: 290px;
    }
`
const StyledLi = styled.li`
    text-decoration: none;
    font-family: Sora;
    padding: 10px;
    margin: 0;
    max-width: 200px;
`
const StyledUl = styled.ul`
    list-style-type: none;
    color: rgba(198, 201, 216, 0.75);
`
const StyledLink = styled(Link)`
    padding: 10px 15px;
    color: #fff;
    background-color: blue;
    text-decoration: none;
    font-size: 18px;
    text-align: center;
    border-radius: 30px;
    border: 1px solid blue;
    width: 135px;
    &:hover {
        background-color: #fff;
        color: blue;
    }
`
const StyledP = styled.p`
    font-family: Marriweather;
    font-weight: 15px;
    font-size: 20px;
    color: #8d9095;
`
const HomeContent = styled.div`
    padding: 125px 0px;
`
const HomeTitle = styled.div``
const HomeText1 = styled.h1`
    font-size: 18px;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 3px;
    color: rgba(198, 201, 216, 0.75) !important;
`
const HomeText2 = styled.h2`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: rgba(198, 201, 216, 0.75) !important;
`
const HomeText3 = styled.h3`
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    font-family: Montserrat, sans-serif;
    color: rgba(198, 201, 216, 0.75) !important;
`
const HomeText4 = styled.h4`
    font-size: 30px;
    line-height: 40px;
    font-weight: 630;
    font-family: Montserrat, sans-serif;
    color: rgba(198, 201, 216, 0.75) !important;
`
const StyledSpan = styled.span`
    color: #c33c8e;
`
const StyledSpan1 = styled.span`
    color: #584995;
`

function Home() {
    return (
        <MyContainer fluid>
            <FirstCol img={background}>
                <Container>
                    <HomeContent>
                        <HomeTitle>
                            <HomeText1>
                                <Typewriter
                                    options = {{delay:10}}
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString(
                                                "Bienvenue dans l'univers du 2.0 ! Laissez-nous digitaliser vos services."
                                            )
                                            .start()
                                    }}
                                />
                            </HomeText1>
                            <HomeText2>
                                Nous sommes <br />
                                <StyledSpan className="pl-10">AKTAION</StyledSpan> <br />
                                <StyledSpan1 >{`ART & TECHS`}</StyledSpan1>
                            </HomeText2>
                            <HomeText3>Votre start-up ivoirienne de communication digitale</HomeText3>
                            <HomeText4></HomeText4>
                        </HomeTitle>
                    </HomeContent>
                </Container>
            </FirstCol>
            <HomeContainer>
                <Row>
                    <Col
                        lg="6"
                        className="text-center text-md-center ms-auto me-auto"
                    >
                        <RightCol>
                            <StyledTitle>
                                Vous recherchez un service particulier ?
                            </StyledTitle>
                            <nav>
                                <StyledUl>
                                    <StyledLi>
                                        <BsFillBrushFill /> La confection d'une
                                        affiche
                                    </StyledLi>
                                    <StyledLi>
                                        <BsFillAwardFill />
                                        La création d'un Site Web
                                    </StyledLi>
                                    <StyledLi>
                                        <BsFillCameraVideoFill /> Le montage
                                        d'une vidéo
                                    </StyledLi>
                                </StyledUl>
                            </nav>

                            <StyledP>Découvrez nos services </StyledP>
                            <StyledLink to="/services">
                                Présentations
                            </StyledLink>
                        </RightCol>
                    </Col>
                    <Col lg="6">
                        <Illustration src={HomeIllustration} />
                    </Col>
                </Row>
            </HomeContainer>
        </MyContainer>
    )
}

export default Home
