import { Button, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DefaultPicture from '../../assets/conception-illustration.png'

function Presentation({ picture, title, text, service }) {
    return (
        <Card className="text-dark text-center">
            <Card.Img variant="top" src={picture} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
            <Card.Body>
                <Button variant="secondary">
                    <Card.Link
                        href={`/service/${service}`}
                        className="text-white"
                    >
                        Voir plus
                    </Card.Link>
                </Button>
            </Card.Body>
        </Card>
    )
}

Presentation.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
}

Presentation.defaultProps = {
    title: '',
    text: '',
    service: '',
    picture: DefaultPicture,
}

export default Presentation
