import OurPresentation from '../../components/OurPresentation'
import back from '../../assets/back.jpg'
import styled from 'styled-components'
import { freelanceProfiles } from '../../datas'
import { Container, Row, Col } from 'react-bootstrap'
import Typewriter from 'typewriter-effect'

const FreelancesWrapper = styled(Container)`
    background: linear-gradient(70deg, #101141, #7370b5);
`
const FirstCol = styled(Row)`
    background-image: url(${(props) => props.img});
    background-color: #010101;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-bottom: 30px;
`
const FreelancesContent = styled.div`
    padding: 125px 0px;
`
const FreelancesTitle = styled.div``

const PresenstationsText = styled.h1`
    font-size: 18px;
    padding: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    color: #fff !important;
`
const FreelancesText1 = styled.h1`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: #fff;
    @media (max-width: 465px) {
        font-size: 50px;
        font-weight: 500;
    }
`
const FreelancesText2 = styled.h2`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: #1c1f28;
    @media (max-width: 463px) {
        font-size: 50px;
        font-weight: 500;
    }
`
const StyledSpan = styled.span`
    color: #000;
`
const StyledSpan2 = styled.span`
    color: #c33c8e;
`

function Freelances() {
    return (
        <FreelancesWrapper fluid>
            <FirstCol img={back}>
                <Container>
                    <FreelancesContent>
                        <FreelancesTitle>
                            <PresenstationsText>
                                <Typewriter
                                    options = {{delay:10}}
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString(
                                                'Une équipe dynamique pour le meilleur service. Faites confiance aux professionels !'
                                            )
                                            .start()
                                    }}
                                />
                            </PresenstationsText>
                            <FreelancesText1>
                                Découvrez <br />
                                <StyledSpan2>nos Prestataires !</StyledSpan2>
                                <br />
                            </FreelancesText1>
                            <FreelancesText2>
                                <StyledSpan>
                                    Imaginez et nous concevons
                                </StyledSpan>
                            </FreelancesText2>
                        </FreelancesTitle>
                    </FreelancesContent>
                </Container>
            </FirstCol>
            <Row>
                {freelanceProfiles.map((profile, index) => (
                    <Col lg="12" md="12">
                        <OurPresentation
                            key={`${profile.name}-${index}`}
                            title={profile.name}
                            picture={profile.picture}
                            job={profile.jobTitle}
                            description={profile.description}
                            citation={profile.citation}
                            auteur={profile.auteur}
                        />
                    </Col>
                ))}
            </Row>
        </FreelancesWrapper>
    )
}

export default Freelances
