import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import HeaderProfile from '../../assets/header-profile.jpg'
import styled from 'styled-components'
import logo from '../../assets/logo.svg'

const StyledNavbar = styled(Navbar)`
    margin: 0px 100px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${(props) => props.img}) center center fixed no-repeat;
    background-size: cover;
    @media (max-width: 857px) {
        margin: 0px 20px;
    }
    @media (max-width: 652px) {
        margin: 0px 10px;
    }
`
const HeaderWrapper = styled.div`
    margin-bottom: 70px;
`
const HomeLogo = styled.img`
    height: 60px;
`
const StyledNavLink = styled(Nav.Link)`
    border-bottom: 0.0625rem solid transparent;
    transition: padding-bottom 0.4s ease-in-out, border-color 0.3s ease;
    &:hover {
        padding-bottom: 0.625rem;
        border-bottom-color: var(--bs-primary);
    }
`
const StyledNavDropdown = styled(NavDropdown)`
    border-bottom: 0.0625rem solid transparent;
    transition: padding-bottom 0.4s ease-in-out, border-color 0.3s ease;
    &:hover {
        padding-bottom: 0.625rem;
        border-bottom-color: var(--bs-primary);
    }
`

const menu1 = [
    {
        titre: 'ACCCUEIL',
        lien: '/',
    },
    {
        titre: 'SERVICES',
        lien: '/services',
    },
]

const menu2 = [
    {
        titre: 'QUI SOMMES-NOUS ?',
        lien: '/about',
    },
    {
        titre: 'EQUIPE',
        lien: '/freelances',
    },
    {
        titre: 'NOS PROJETS',
        lien: '/projects',
    },
]

function Header() {
    const url = new URL(document.location.href)
    switch (url.pathname) {
        case '/':
            document.title =
                "Création de site internet en Côte d'Ivoire, conceptions graphiques, marketing digital - Aktaion Art and Techs"
            break
        case '/services':
            document.title =
                "site internet, montage vidéo, applications mobile, community management, marketing digital en Côte d'Ivoire à petit prix - Aktaion Art and Techs"
            break
        case '/freelances':
            document.title =
                "Développeurs d'applications mobiles et web, infographes, community manager en Côte d'Ivoire"
            break
        case '/service/website':
            document.title =
                'Création, hébergement et référencement de site web à Abidjan - Aktaion Art and Techs'
            break
        case '/service/posters':
            document.title =
                "Conception d'affiches publicitaires, de kakémonos, de flyers et montage vidéo à Abidjan - Aktaion Art and Techs"
            break
        case '/service/mobileapps':
            document.title =
                "Création d'applications mobiles en Côte d'ivoire - Aktaion Art and Techs"
            break
        case '/service/logo':
            document.title =
                "Conception de carte de visite, de logo à Abidjan, Côte d'Ivoire - Aktaion Art and Techs"
            break
        case '/about':
            document.title =
                "Découvrez l'organisation de notre start-up - AKTAION ART AND TECHS"
            break
        case '/projects':
            document.title =
                'Parce que tout développement passe par des projets technologiqus aussi - AKTAION ART AND TECHS'
            break
        default:
            document.title =
                "Votre agence de communication digitale à Abidjan en Côte d'Ivoire - Aktaion Art and Techs"
    }

    return (
        <HeaderWrapper>
            <StyledNavbar
                img={HeaderProfile}
                bg="transparent"
                expand="lg"
                fixed="top"
            >
                <Container>
                    <Navbar.Brand href="/" className="text-light">
                        <HomeLogo src={logo} alt="logo-image" />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        style={{ border: '1px solid rgb(34, 66, 124)' }}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {menu1.map((item) => (
                                <StyledNavLink
                                    href={item.lien}
                                    className="text-light mx-2"
                                    key={item.titre}
                                >
                                    {item.titre}
                                </StyledNavLink>
                            ))}
                            <StyledNavDropdown
                                className="text-light mx-2"
                                title={
                                    <span className="text-light my-auto">
                                        A PROPOS
                                    </span>
                                }
                                menuVariant="dark"
                                variant="primary"
                            >
                                {menu2.map((item) => (
                                    <NavDropdown.Item
                                        className="text-light mx-2"
                                        href={item.lien}
                                        key={item.titre}
                                    >
                                        {item.titre}
                                    </NavDropdown.Item>
                                ))}
                            </StyledNavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </StyledNavbar>
        </HeaderWrapper>
    )
}
export default Header
