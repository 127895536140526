import styled from 'styled-components'
import bg from '../../assets/bg.jpg'
import Service from '../../components/Service'
import { Row, Col, Container } from 'react-bootstrap'
import Presentation from '../../components/Presentation'
import { ourPresentations } from '../../datas'
import Typewriter from 'typewriter-effect'

const MyContainer = styled(Container)`
    background: linear-gradient(70deg, #101141, #7370b5) !important;
`
const PresentationsWrapper = styled.div`
    padding: 30px;
`
const FirstCol = styled(Row)`
    background-image: url(${(props) => props.img});
    background-color: #010101;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
`
const PresentationsContent = styled.div`
    padding: 125px 0px;
`
const PresentationsTitle = styled.div``

const PresenstationsText = styled.h1`
    color: #fff;
    font-size: 18px;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 3px;
    color: omicron !important;
`
const PresentationsText1 = styled.h1`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: #9097aa;
    @media (max-width: 463px) {
        font-size: 50px;
        font-weight: 500;
    }
`

const StyledStrong = styled.strong`
    color: #c33c8e;
`

function Presentations() {
    return (
        <MyContainer fluid>
            <FirstCol img={bg}>
                <Container>
                    <PresentationsContent>
                        <PresentationsTitle>
                            <PresenstationsText>
                                <Typewriter
                                options = {{delay:10}}
                                    onInit={(typewriter) => {        
                                        typewriter
                                            .typeString(
                                                'Besoin de site internet, affiches publicitaires, logos, community manager ?'
                                            )
                                            .start()
                                    }}
                                />
                            </PresenstationsText>
                            <PresentationsText1>
                                <StyledStrong> Nos Différentes </StyledStrong><br />
                                Prestations
                                <br />
                            </PresentationsText1>
                        </PresentationsTitle>
                    </PresentationsContent>
                </Container>
            </FirstCol>
            <PresentationsWrapper>
                <Row className="my-5">
                    {ourPresentations.map((present, index) => (
                        <Col lg="6" md="12" className="g-3">
                            <Presentation
                                key={`${present.title}-${index}`}
                                title={present.title}
                                text={present.text}
                                service={present.service}
                                picture={present.picture}
                            />
                        </Col>
                    ))}
                </Row>
                <Row className="my-5">
                    <Service />
                </Row>
            </PresentationsWrapper>
        </MyContainer>
    )
}

export default Presentations
