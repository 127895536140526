import NousSommmes from '../../components/NousSommes'
import back from '../../assets/bg-qsn.jpg'
import styled from 'styled-components'
import { WeAreProfiles } from '../../datas'
import { Container, Row, Col } from 'react-bootstrap'
import Typewriter from 'typewriter-effect'

const QuiSommesNousWrapper = styled(Container)`
    background: linear-gradient(70deg, #101141, #7370b5);
`
const FirstCol = styled(Row)`
    background-image: url(${(props) => props.img});
    background-color: #010101;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-bottom: 30px;
`
const QuiSommesNousContent = styled.div`
    padding: 125px 0px;
`
const QuiSommesNousTitle = styled.div``

const PresenstationsText = styled.h1`
    font-size: 18px;
    padding: 10px;
    font-weight: 400;
    letter-spacing: 3px;
    color: #fff !important;
`
const QuiSommesNousText1 = styled.h1`
    font-size: 54px;
    padding: 10px;
    line-height: 54px;
    font-weight: 800;
    font-family: Montserrat, sans-serif;
    color: #fff;
    @media (max-width: 463px) {
        font-size: 50px;
        font-weight: 500;
    }
`
const StyledSpan = styled.span`
    color: #584995;
`
const StyledSpan2 = styled.span`
    color: #c33c8e;
`

function QuiSommesNous() {
    return (
        <QuiSommesNousWrapper fluid>
            <FirstCol img={back}>
                <Container>
                    <QuiSommesNousContent>
                        <QuiSommesNousTitle>
                            <PresenstationsText>
                                <Typewriter 
                                    options = {{delay:10}}
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString(
                                                'Pensez à une idée et nous la digitalisons !'
                                            )
                                            .start()
                                    }}
                                />
                            </PresenstationsText>
                            <QuiSommesNousText1>
                                <StyledSpan2>QUI </StyledSpan2>
                                <br />
                                <StyledSpan>SOMMES-NOUS !</StyledSpan>
                                <br />
                            </QuiSommesNousText1>
                        </QuiSommesNousTitle>
                    </QuiSommesNousContent>
                </Container>
            </FirstCol>
            <Row>
                {WeAreProfiles.map((profile, index) => (
                    <Col lg="12" md="12">
                        <NousSommmes
                            key={`${profile.title}-${index}`}
                            title={profile.title}
                            illustration={profile.illustration}
                            details={profile.details}
                        />
                    </Col>
                ))}
            </Row>
        </QuiSommesNousWrapper>
    )
}

export default QuiSommesNous
