import styled from 'styled-components'
import ErrorImage from '../../assets/error-404.png'

const ErrorWrapper = styled.div`
    margin: 35px;
    padding-top: 40px;
    margin: 35px;
    @media (max-width: 994px) {
        padding-top: 160px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
`
const ErrorTitle = styled.h1`
    color: #8650fc;
    font-weight: 300px;
`
const Illustration = styled.img`
    max-width: 1000px;
    @media (max-width: 1201px) {
        max-width: 700px;
    }
    @media (max-width: 902px) {
        max-width: 500px;
    }
    @media (max-width: 523px) {
        max-width: 330px;
    }
`
function Error() {
    return (
        <ErrorWrapper>
            <ErrorTitle>
                Il semblerait que la page que vous recherchez n'existe pas
            </ErrorTitle>
            <Illustration src={ErrorImage} />
        </ErrorWrapper>
    )
}

export default Error
