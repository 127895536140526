import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DefaultPicture from '../../assets/conception-illustration.png'
import styled from 'styled-components'

const ProjetPresentationWrapper = styled.div`
    background: transparent;
    color: #000;
`
const ProjetPresentationImg = styled.img`
    border-radius: 10px;
    width: 95%;
    height: 85%;
`
const ProjetPresentationHeading = styled.h1`
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    @media (max-width: 463px) {
        font-size: 30px;
        font-weight: 500;
    }
`
const ProjetPresentationText = styled.p`
    color: rgba(198, 201, 216, 0.75);
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
    @media (max-width: 1024px) {
        font-size: 15px !important;
    }
`

function ProjetPresentation({ title, picture, description }) {
    return (
        <ProjetPresentationWrapper>
            <Container>
                <Row>
                    <Col lg={6}>
                        <ProjetPresentationImg src={picture} />
                    </Col>
                    <Col lg={6}>
                        <ProjetPresentationHeading className="pt-1">
                            {title}
                        </ProjetPresentationHeading>
                        <Container>
                            <ProjetPresentationText className="py-2">
                                {description}
                            </ProjetPresentationText>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </ProjetPresentationWrapper>
    )
}

ProjetPresentation.propTypes = {
    title: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}
ProjetPresentation.defaultProps = {
    title: 'Lorem ipsum',
    picture: DefaultPicture,
    description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum deserunt voluptate necessitatibus a sequi, temporibus ipsa impedit incidunt tempore alias! Laborum ratione est corporis corrupti quas, atque facere ipsa harum!',
}

export default ProjetPresentation
