import { Card, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTitle = styled(Card.Title)`
    color: #6c747f;
`
const StyledText = styled(Card.Text)`
    text-align : justify;
`
const contact = '2250778475178'
const url = new URL(window.location.href)
const text = "Hello !\r Je suis intéressé(e) par ce service. Pouvez-vous me donner plus d'infos ?\r"

function ServiceCard({ name, picture, description, id}) {
    return (
        <Card className="m-3" id={id}>
            <Card.Img variant="top" src={picture}  alt={name}/>
            <Card.Body>
                <StyledTitle>{name}</StyledTitle>
                <StyledText height='100px' className="justify-content-start">
                    {description}
                </StyledText>
                <Button variant="primary" href={`https://api.whatsapp.com/send?phone=`+contact+'&text='+text+url.href}>Commander</Button>
            </Card.Body>
        </Card>
    )
}
ServiceCard.propTypes = {
    name: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
}

ServiceCard.defaultProps = {
    name: '',
    picture: '',
    description: '',
    price: '',
}

export default ServiceCard
