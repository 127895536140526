import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DefaultPicture from '../../assets/conception-illustration.png'
import styled from 'styled-components'

const NousSommesWrapper = styled.div`
    background: transparent;
    color: #000;
`
const NousSommesImg = styled.img`
    border-radius: 10px;
    width: 95%;
    height: 85%;
`
const NousSommesHeading = styled.h1`
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 30px;
    @media (max-width: 463px) {
        font-size: 30px;
        font-weight: 500;
    }
`
const NousSommesText = styled.p`
    color: rgba(198, 201, 216, 0.75);
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    font-family: Poppins, sans-serif;
    margin-bottom: 15px;
    @media (max-width: 1024px) {
        font-size: 15px !important;
    }
`

function NousSommes({ title, illustration, details }) {
    return (
        <NousSommesWrapper>
            <Container>
                <Row>
                    <Col lg={6}>
                        <NousSommesImg src={illustration} />
                    </Col>
                    <Col lg={6}>
                        <NousSommesHeading className="pt-1">
                            {title}
                        </NousSommesHeading>
                        <Container>
                            <NousSommesText className="py-2">
                                {details}
                            </NousSommesText>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </NousSommesWrapper>
    )
}

NousSommes.propTypes = {
    title: PropTypes.string.isRequired,
    illustration: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
}
NousSommes.defaultProps = {
    title: '',
    illustration: DefaultPicture,
    details: '',
}

export default NousSommes
