import { createGlobalStyle } from 'styled-components'

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Trebuchet MS', Helvetica, sans-serif;
    }
    html {
        background: rgb(149,141,216);
        background: linear-gradient(90deg, rgba(149,141,216,1) 27%, rgba(186,186,222,1) 46%, rgba(174,174,234,1) 63%, rgba(177,181,216,1) 80%);
    }
    body {
        background-color: '#8650fc';
        margin: 0px 100px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    @media (max-width: 857px) {
        body {
            margin: 0px 20px;
        }
    }
    @media (max-width: 652px) {
        body {
            margin: 0px 10px;
        }
    }

`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle
