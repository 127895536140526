import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { affiches, logos, sites, mobileapps, seo } from '../../datas'
import ServiceCard from '../../components/ServiceCard'
import { Col, Container, Row } from 'react-bootstrap'
import Error from '../../components/Error'

const StyledServicesWrapper = styled.div`
    padding: 35px;
    @media (max-width: 994px) {
        padding-top: 70px;
    }
    background: rgb(149, 141, 216);
    background: linear-gradient(
        90deg,
        rgba(149, 141, 216, 1) 27%,
        rgba(186, 186, 222, 1) 46%,
        rgba(174, 174, 234, 1) 63%,
        rgba(177, 181, 216, 1) 80%
    );
`

function Services() {
    const { serviceName } = useParams()
    
    let ThisService
    switch (serviceName) {
        case 'posters':
            ThisService = affiches
            break
        case 'logos':
            ThisService = logos
            break
        case 'websites':
            ThisService = sites
            break
        case 'mobileapps':
            ThisService = mobileapps
            break
        case 'seo' :
            ThisService = seo
            break
        default:
            ThisService = false
            break
    }
    return ThisService ? (
            <StyledServicesWrapper>
                <Container>
                    <Row className="g-3">
                        {serviceName
                            ? ThisService.map((service, index) => (
                                  <Col md={6} lg={6}>
                                      <ServiceCard
                                          key={`${service.name}-${index}`}
                                          name={service.name}
                                          picture={service.picture}
                                          description={service.description}
                                      />
                                  </Col>)): null }
                    </Row>
                </Container>
            </StyledServicesWrapper>
    ) : (
        <Error />
    )
}

export default Services
